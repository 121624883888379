import { observer } from 'mobx-react';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useMobxStores } from '@stores/store';
import { isEmpty as _isEmpty } from 'lodash';

export const Context = React.createContext({});

const Sound = props => {
  const { settingStore } = useMobxStores();

  const [bgmCode, setBgmCode] = useState('');
  const [bgm, setBgm] = useState([]);
  const [bgmIdx, setBgmIdx] = useState(0);

  const audioRef = useRef();

  const getInitialBgmData = useCallback(
    async bgmCode => {
      await settingStore.getBgm(bgmCode);
      setBgm(settingStore.bgmList[bgmCode]);
      return true;
    },
    [settingStore]
  );

  useEffect(() => {
    getInitialBgmData(bgmCode);
  }, [bgmCode]);

  useEffect(() => {
    settingAudioSrc(true);
    // initialBgmPlay();
  }, [bgm]);

  // const initialBgmPlay = ()=>{
  //   if(!_isEmpty(bgm) && bgm.length > 0 && settingStore.bgmPlayStatus[bgmCode] === 1){
  //     var promise =  audioRef.current?.play();
  //     if (promise !== undefined) {
  //       console.log('??')
  //       promise.then(_ => {
  //         audioRef.current.play();
  //       }).catch(() => {
  //         setBgmPlayStatus(0);
  //       });
  //     }
  //   }
  // }

  const setBgmPlayStatus = status => {
    settingStore.setBgmPlayStatus(bgmCode, status);
  };

  const onPlayAudio = () => {
    setBgmPlayStatus(1);
    audioRef.current.addEventListener('ended', () => {
      const nextBgmIdx = bgm.length > bgmIdx + 1 ? bgmIdx + 1 : 0;
      if (nextBgmIdx === bgmIdx) {
        settingAudioSrc(false);
      } else {
        setBgmIdx(nextBgmIdx);
      }
    });
  };
  const onPauseAudio = () => {
    setBgmPlayStatus(0);
  };

  const onClickBgmStop = () => {
    audioRef.current?.pause();
  };

  const onClickBgmStart = () => {
    var promise = audioRef.current?.play();
    if (promise !== undefined) {
      promise
        .then(_ => {
          audioRef.current.play();
        })
        .catch(() => {
          setBgmPlayStatus(0);
        });
    }
  };

  const settingAudioSrc = isInit => {
    if (!_isEmpty(bgm)) {
      audioRef.current.src = bgm[bgmIdx].bgmSrc;
      if (
        (isInit && settingStore.bgmPlayStatus[bgmCode] === 1) ||
        (!isInit && bgm[bgmIdx].autoPlay === 1)
      ) {
        onClickBgmStart();
      } else {
        onClickBgmStop();
      }
    } else if (audioRef.current) {
      audioRef.current.src = null;
    }
  };

  useEffect(() => {
    if (!_isEmpty(bgm) && bgm.length > bgmIdx) {
      settingAudioSrc(false);
    }
  }, [bgmIdx]);

  return (
    <Context.Provider
      value={{ setBgmCode, bgm, bgmIdx, onClickBgmStop, onClickBgmStart }}
    >
      {props.children}
      {!_isEmpty(bgm) && (
        <audio
          ref={audioRef}
          onPlay={onPlayAudio}
          onPause={onPauseAudio}
          // controls={true}
        >
          {!_isEmpty(bgm) &&
            bgm.map((row, index) => (
              <source src={row.bgmSrc} key={`bgm_audio_${index}`} />
            ))}
        </audio>
      )}
    </Context.Provider>
  );
};

// export default Sound;

export default observer(Sound);
