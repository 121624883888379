import { makeAutoObservable, runInAction } from 'mobx';

import createAxiosInstance from '@helpers/AxiosHelper';
import { paramsToFormdata } from '@helpers/StringHelper';
import { isEmpty as _isEmpty } from 'lodash';

const initialList = {
  contents: [],
  totalElements: 0,
  totalPages: 1,
  page: 1,
  size: 10,
};

const initial = {
  gameList: {
    ...initialList,
    query: {
      lang: '',
    },
  },
};

export function createGameStore(rootStore) {
  const store = makeAutoObservable({
    gameList: initial.gameList,

    async getGameList(lang) {
      try {
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(`/game`, paramsToFormdata({ lang }));
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.gameList.contents = data.contents;
            this.gameList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.gameList.totalElements = data.totalElements;
            this.gameList.size = data.size;
            this.gameList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getGameList.v::', err);
      }
      return false;
    },
  });

  return store;
}
