import { consentCookiesKey, expireConfig } from '@stores/SettingStore';
import moment from 'moment';
import { isMobileWithOutIpad } from '@helpers/EnvHelper';
import i18next from 'i18next';

export const saveConsentCookiesTimes = () => {
  localStorage.setItem(
    consentCookiesKey.times,
    JSON.stringify(moment(new Date().getTime()).unix())
  );
  localStorage.setItem(
    consentCookiesKey.expire,
    JSON.stringify(
      moment(new Date().getTime())
        .add(expireConfig.amount, expireConfig.unit)
        .unix()
    )
  );
};

export const getArrayFromJson = str => {
  if (!str) return [];

  try {
    return JSON.parse(str);
  } catch (e) {
    return [];
  }
};

export const getStringFromJson = str => {
  if (!str) return '';

  try {
    return JSON.parse(str);
  } catch (e) {
    return '';
  }
};

export const copyToClip = async title => {
  if (isMobileWithOutIpad()) {
    try {
      navigator
        .share({
          title: title,
          text: '',
          url: window.location,
        })
        .then(() => console.log('공유 성공'))
        .catch(error => console.log('공유 실패', error));
    } catch (e) {
      await navigator.clipboard.writeText(window.location);
      alert(`${i18next.t('복사되었습니다.')}`);
    }
  } else {
    await navigator.clipboard.writeText(window.location);
    alert(`${i18next.t('복사되었습니다.')}`);
  }
};
