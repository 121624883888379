import { makeAutoObservable, runInAction } from 'mobx';
import { has as _has, toNumber as _toNumber } from 'lodash';
import { createStaticInstance } from '@helpers/AxiosHelper';
import { GAME_DATA_URL } from '@helpers/EnvHelper';
import { event0601GetStep } from '@helpers/EventUtil';

const initial = {};

export function createEventsStore(rootStore) {
  const store = makeAutoObservable({
    sectionRef: {
      section01: null,
      section02: null,
    },
    event0601: {
      heart_count: 0,
      current_step: 0,
    },
    setSectionRef(type, ref) {
      runInAction(() => {
        if (type === 1) this.sectionRef.section01 = ref;
        if (type === 2) this.sectionRef.section02 = ref;
      });
    },
    setListQuery(listName, query) {
      if (!_has(this, listName)) return;
      runInAction(() => {
        this[listName].query = {
          ...this[listName].query,
          ...query,
        };
      });
    },

    async getEvent0601Heart() {
      try {
        const axios = createStaticInstance(rootStore);

        let res = await axios.get(`${GAME_DATA_URL}/events/hearts202306.json`);

        if (res.status === 200) {
          const data = res.data;
          runInAction(() => {
            const count =
              data?.heart_count > 0 ? _toNumber(data.heart_count) : 0;

            if (count) {
              this.event0601.heart_count = count;
              this.event0601.current_step = event0601GetStep(count);
            }
          });
          return true;
        }
        return true;
      } catch (err) {
        runInAction(() => {
          this.event0601.heart_count = 0;
        });
        console.error('heart_count v::', err);
      }
      return false;
    },
  });
  return store;
}
