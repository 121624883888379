import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './index.css';
import '@assets/scss/hyeon.scss';
// import '@assets/fonts/_fonts.css';
// import '@assets/fonts/icon/css/fontello.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';

import { getRoutes } from '@router';
import { useTranslation } from 'react-i18next';
import { isEmpty as _isEmpty, indexOf as _indexOf, get as _get } from 'lodash';

import { LANGUAGE, isIE, GFH_ENV } from '@helpers/EnvHelper';
import Sound from '@router/Sound';
import { useMobxStores } from '@stores/store';

import PageInit from '@app/router/PageInit';

// import Modals from '@molecules/modal/Modals';

import Modal from '@app/components/molecules/modal/ieIndex';
import IeDeprecated from '@app/components/molecules/modal/IeDeprecated';
import { getArrayFromJson } from '@helpers/Util';
import { consentCookiesKey } from '@stores/SettingStore';
import { Switch } from '@headlessui/react';
import NotFoundPage from '@pages/NotFoundPage';
import MetaTagsLayer from '@molecules/MetaTagsLayer';
export const ModalContext = React.createContext(null);

function App() {
  const { t, i18n } = useTranslation();
  const { settingStore, mainStore } = useMobxStores();

  //다국어 설정 - path 인지
  const pathStr = window.location.pathname;
  const paths = pathStr.split('/');

  if (
    !_isEmpty(paths) &&
    paths.length > 1 &&
    _indexOf(LANGUAGE, paths[1]) !== -1
  ) {
    //path에 다국어 선택되어 노출되어 있는 경우
    // if(i18n.language !== paths[1] ){
    i18n.changeLanguage(paths[1]);
    // }
  } else if (navigator.userAgent === 'ReactSnap') {
    i18n.changeLanguage('kr');
  } else {
    const INTHESEOM_LANG = localStorage.getItem('INTHESEOM_LANG');
    if (!_isEmpty(INTHESEOM_LANG)) {
      //다국어 선택했던 언어가 있는지 확인
      i18n.changeLanguage(INTHESEOM_LANG);
    } else if (!_isEmpty(navigator.language)) {
      //브라우저 확인하여 언어셋팅
      let lang = navigator.language.toLowerCase().substring(0, 2);
      lang = lang === 'ko' ? 'kr' : lang === 'ja' ? 'jp' : lang;
      if (_indexOf(LANGUAGE, lang) !== -1) {
        i18n.changeLanguage(lang);
      }
    } else {
      i18n.changeLanguage('kr');
    }
  }
  localStorage.setItem('INTHESEOM_LANG', i18n.language);

  window.document
    .querySelector('meta[name="keywords"]')
    .setAttribute('content', t('meta_keywords'));

  window.document
    .querySelector('meta[name="twitter:card"]')
    .setAttribute('content', 'summary_large_image');

  //meta tag

  //meta tag
  if (window.twttr != null) {
    window.twttr.conversion.trackPid(t('twttr_pid'), {
      tw_sale_amount: 0,
      tw_order_quantity: 0,
    });
    // console.log(`window.twttr.conversion.trackPid(${t('twttr_pid')}, { tw_sale_amount: 0, tw_order_quantity: 0 })`);
  }
  let consentCookies = getArrayFromJson(
    localStorage.getItem(consentCookiesKey.cookies)
  );

  let isAnalytical = consentCookies.includes('__analytical');
  let isAds = consentCookies.includes('__ads');

  settingStore.getFooter();
  mainStore.getKeyvisual(i18n.language);

  //meta image 분류

  useEffect(() => {
    let imageName;
    let metaTitle = 'meta_title';
    let metaDescription = 'meta_description';
    const metaUrl = window.location.href.split('?')[0]; // 쿼리 문자열을 제거합니다.
    const CURRENT_TIME = new Date().getTime();
    const FESTA_TIME = new Date('2024-07-09T09:59:59+09:00').getTime();

    if (pathStr.includes('community/news')) {
      imageName = 'meta_notice_image';
    } else if (pathStr.includes('community/event')) {
      imageName = 'meta_event_image';
    } else {
      imageName = 'meta_image';
      metaTitle = 'meta_title';
      metaDescription = 'meta_description';
    }

    window.document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute('content', t(imageName));
    window.document
      .querySelector('meta[property="og:image"]')
      .setAttribute('content', t(imageName));

    window.document
      .querySelector('meta[name="twitter:url"]')
      .setAttribute('content', metaUrl);
    window.document
      .querySelector('meta[property="og:url"]')
      .setAttribute('content', metaUrl);

    window.document
      .querySelector('meta[name="title"]')
      .setAttribute('content', t(metaTitle));
    window.document
      .querySelector('meta[name="twitter:title"]')
      .setAttribute('content', t(metaTitle));

    window.document
      .querySelector('meta[property="og:title"]')
      .setAttribute('content', t(metaTitle));

    window.document
      .querySelector('meta[name="description"]')
      .setAttribute('content', t(metaDescription));
    window.document
      .querySelector('meta[name="twitter:description"]')
      .setAttribute('content', t(metaDescription));
    window.document
      .querySelector('meta[property="og:description"]')
      .setAttribute('content', t(metaDescription));

    window.document.title = t(metaTitle);
    window.document.documentElement.lang = t('meta_html_lang');
  }, [pathStr]);

  return (
    <>
      <BrowserRouter>
        {/* Link 이동시 최상위로 이동  */}
        <PageInit />
        <Sound>
          {isIE() && (
            <Modal isOpen={true}>
              <IeDeprecated />
            </Modal>
          )}
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src={`https://analytics.twitter.com/i/adsct?txn_id=${t(
              'twttr_pid'
            )}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0`}
          />
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src={`//t.co/i/adsct?txn_id=${t(
              'twttr_pid'
            )}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0`}
          />
          <div className="page_container">
            <React.Suspense fallback={<></>}>
              <Routes>
                {getRoutes().map((route, idx) => {
                  if (
                    navigator.userAgent === 'ReactSnap' &&
                    route.name == 'not found page'
                  ) {
                    return null;
                  }
                  let seoTitle =
                    route.path != '/*'
                      ? t(route.name) + ' - ' + t('meta_title')
                      : t('meta_title');
                  return route.component ? (
                    <Route
                      key={idx}
                      path={`${route.path}`}
                      exact={_get(route, 'exact', true)}
                      name={route.name}
                      element={
                        <route.layout headerIsblack={route.headerIsblack}>
                          <HelmetProvider>
                            <Helmet>
                              <title>{seoTitle}</title>
                            </Helmet>
                          </HelmetProvider>
                          <route.component isLink={route.isLink} />
                        </route.layout>
                      }
                    />
                  ) : null;
                })}

                {/* <Route render={(props) => {window.location.replace("/");} } /> */}
              </Routes>
            </React.Suspense>
            {(GFH_ENV === 'qa' || GFH_ENV === 'local') && <MetaTagsLayer />}
          </div>
        </Sound>
      </BrowserRouter>
    </>
  );
}

export default observer(App);
