import { makeAutoObservable, runInAction } from 'mobx';

import createInstance, { createStaticInstance } from '@helpers/AxiosHelper';
import { isEmpty as _isEmpty, has as _has } from 'lodash';
import { paramsToFormdata } from '@helpers/StringHelper';
import { GAME_DATA_URL } from '@helpers/EnvHelper';

const initialList = {
  level: [],
  style: [],
};

const initial = {
  ranking: [],
  banner: {
    tab: 'level',
    top: '',
    bottom: '',
  },
  current: {
    year: '',
    month: '',
    season: '',
    seasonFilename: '',
  },
};

export function createRankingStore(rootStore) {
  const store = makeAutoObservable({
    banner: initial.banner,
    group: [],
    selectYear: [],
    selectMonth: [],
    current: initial.current,
    rankingList: initial.ranking,
    clubIcon: {},
    profileIcon: {},

    setListQuery(listName, query) {
      if (!_has(this, listName)) return;
      runInAction(() => {
        this[listName].query = {
          ...this[listName].query,
          ...query,
        };
      });
    },

    setCurrentYear(year) {
      runInAction(() => {
        this.current.year = year;
      });
    },

    setCurrentMonth(month) {
      runInAction(() => {
        this.current.month = month;
      });
    },

    setCurrentSeason(season) {
      runInAction(() => {
        this.current.season = season;
      });
    },

    setCurrentId(seasonId) {
      runInAction(() => {
        if (seasonId) this.current.seasonFilename = seasonId;
      });
    },
    setTab(type) {
      runInAction(() => {
        type = type == 'level' || type == 'style' ? type : 'level';
        this.banner.tab = type;
        this.group = [];
        this.current = initial.current;
      });
    },

    setYear(year) {
      runInAction(() => {
        this.selectYear = year;
      });
    },

    setInitialList() {
      runInAction(() => {
        this.rankingList = initial.ranking;
      });
    },

    async getBanner(lang, type) {
      try {
        const axios = createInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        type = type == 'level' || type == 'style' ? type : 'level';
        //console.log('===');
        let res = await axios.post(
          `/ranking/banner`,
          paramsToFormdata({ lang, type })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;
          runInAction(() => {
            this.banner.tab = type;
            this.banner.top = data.topUrl;
            this.banner.bottom = data.bottomUrl;
          });
          return true;
        }
      } catch (err) {
        console.error('ranking-banner v::', err);
      }
      return false;
    },

    async getAssetsClubIconInfo() {
      try {
        const axios = createStaticInstance(rootStore);

        let res = await axios.get(`${GAME_DATA_URL}/assets/club_icon.json`);

        if (res.status === 200) {
          return res.data;
        }
        return true;
      } catch (err) {
        console.error('ranking-banner v::', err);
      }
      return false;
    },

    async getAssetsProfileIconInfo() {
      try {
        const axios = createStaticInstance(rootStore);

        let res = await axios.get(`${GAME_DATA_URL}/assets/user_icon.json`);

        if (res.status === 200) {
          return res.data;
        }
        return true;
      } catch (err) {
        console.error('ranking-banner v::', err);
      }
      return false;
    },

    async getLeaderBoardGroup(type) {
      try {
        const axios = createStaticInstance(rootStore);
        type = type == 'level' || type == 'style' ? type : 'level';

        let id = 'levels';
        if (type == 'style') id = 'styles';

        let res = await axios.get(`${GAME_DATA_URL}/leaderboard/${id}.json`);

        if (res.status === 200) {
          const data = res.data;
          runInAction(() => {
            if (type == 'style') {
              this.group = data.styles;
            } else {
              this.group = data.levels;
            }
          });
          return true;
        }
        return true;
      } catch (err) {
        runInAction(() => {
          this.group = [];
        });
        console.error('ranking-banner v::', err);
      }
      return false;
    },

    async getLeaderBoardList(type, id) {
      try {
        const axios = createStaticInstance(rootStore);
        //console.log(`${GAME_DATA_URL}/leaderboard/${id}.json`);
        let res = await axios.get(`${GAME_DATA_URL}/leaderboard/${id}.json`);

        if (res.status === 200) {
          let clubIconPathJson = await this.getAssetsClubIconInfo();
          let profileIconPathJson = await this.getAssetsProfileIconInfo();
          const data = res.data;
          runInAction(async () => {
            let clubIconPath = '';
            let profileIconPath =
              'UI/social/user_icon/social_user_profile_base.png';
            let list = data.highRankerList.reduce(function (a, o) {
              let clubIcons = clubIconPathJson.filter(function (data) {
                return data.Id == o.clubEmblem;
              });

              let profileIcons = profileIconPathJson.filter(function (data) {
                return data.Id == o.userProfileIcon;
              });

              if (clubIcons[0] && clubIcons[0]['IconPath'] !== undefined) {
                clubIconPath = clubIcons[0].IconPath;
              }

              if (
                profileIcons[0] &&
                profileIcons[0]['IconPath'] !== undefined
              ) {
                profileIconPath = profileIcons[0].IconPath;
              }

              a.push({ clubIconPath, profileIconPath, ...o });
              return a;
            }, []);
            //console.log(list);
            this.rankingList = list;
          });
          return true;
        } else {
        }
        return true;
      } catch (err) {
        console.error('ranking-rankingList v::', err);
      }
      return false;
    },
  });
  return store;
}
