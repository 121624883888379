// service
export const API_URL =
  navigator.userAgent !== 'ReactSnap'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_SSR_URL;
export const API_EXTERNAL_URL = process.env.REACT_APP_EXTERNAL_API_URL;
export const GAME_DATA_URL = process.env.REACT_APP_GAME_DATA_URL;
export const IMAGE_DOMAIN = process.env.IMAGE_DOMAIN;
export const YOUTUBE_DOMAIN = process.env.YOUTUBE_DOMAIN;
export const GFH_ENV = process.env.REACT_APP_GFH_ENV;

export const POLICY_SITE = process.env.REACT_APP_POLICY_SITE;

// assets 사용하지 않음 사용할때 재정의 필요
//export const ASSETS_IMAGE_PATH =process.env.REACT_APP_DOMAIN + "/img";
//export const ASSETS_VIDEO_PATH =process.env.REACT_APP_DOMAIN + "/video";
//export const ASSETS_BGM_PATH =process.env.REACT_APP_DOMAIN + "/bgm";

export const GAMECODE = 'intheseom';
export const LANGUAGE = ['kr', 'ko', 'en', 'jp'];

export const isMobile = () => {
  const agent = navigator.userAgent;
  if (
    agent.match(
      /iPhone|iPod|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    ) != null ||
    agent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true;
  } else {
    return false;
  }
};

export const isMobileWithOutIpad = () => {
  const agent = navigator.userAgent;
  if (
    agent.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    ) != null ||
    agent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true;
  } else {
    return false;
  }
};

export const isAOS = () => {
  const agent = navigator.userAgent;
  if (
    agent.match(
      /Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    ) != null ||
    agent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true;
  } else {
    return false;
  }
};

export const isIOS = () => {
  const agent = navigator.userAgent.toLowerCase();

  if (agent.match(/iphone|ipod|ipad/i)) {
    return true;
  } else {
    return false;
  }
};

export const isIE = () => {
  const agent = navigator.userAgent.toLowerCase();
  if (
    (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) ||
    agent.indexOf('msie') !== -1
  ) {
    return true;
  }
  return false;
};

export const comTracking = (type, twttrId) => {
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('track', type);
    }

    if (window.twttr != null) {
      window.twttr.conversion.trackPid(twttrId, {
        tw_sale_amount: 0,
        tw_order_quantity: 0,
      });
      // console.log(` window.twttr.conversion.trackPid(${twttrId}, { tw_sale_amount: 0, tw_order_quantity: 0 })`)
    }
  }
};

export const countryCodeToLanguage = {
  en: 'en',
  kr: 'ko',
  jp: 'ja',
};
