import React, { useState, useEffect } from 'react';

const MetaTagsLayer = () => {
  const [metaTags, setMetaTags] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  function settingMetaTags() {
    const tags = Array.from(document.querySelectorAll('meta')).map(tag => ({
      name: tag.getAttribute('name'),
      property: tag.getAttribute('property'),
      content: tag.getAttribute('content'),
    }));
    setMetaTags(tags);
  }

  const toggleVisibility = () => {
    settingMetaTags();
    setIsVisible(!isVisible);
  };
  return (
    <div className="w-full fixed top-[200px]">
      <button
        onClick={toggleVisibility}
        className="absolute top-[200px] left-0 m-4 p-2 bg-blue-500 text-white z-110"
      >
        {isVisible ? '메타숨기기' : '메타보이기'}
      </button>

      {isVisible && (
        <div className=" absolute top-[250px] left-[15px] bg-black  w-[500px] h-[200px] overflow-y-scroll">
          <ul className=" mb-[400px] p-5">
            {metaTags.map((tag, index) => (
              <li key={index} className="text-white">
                <>
                  {(tag.name || tag.property) && (
                    <>
                      <strong>{tag.name || tag.property}</strong>: {tag.content}{' '}
                    </>
                  )}
                  {tag.property === 'og:image' ||
                  tag.name === 'twitter:image' ? (
                    <div>
                      <img
                        src={tag.content}
                        alt="Meta Tag Image"
                        className="mt-2 w-full"
                      />
                    </div>
                  ) : null}
                </>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MetaTagsLayer;
