import { makeAutoObservable, runInAction } from 'mobx';

import createAxiosInstance from '@helpers/AxiosHelper';
import { getSearchForm } from '@helpers/StringHelper';
import { isEmpty as _isEmpty, has as _has } from 'lodash';
import { paramsToFormdata } from '@helpers/StringHelper';

const initialList = {
  contents: [],
  totalElements: 0,
  totalPages: 1,
  page: 1,
  size: 10,
};

const initialCategory = [
  {
    koCategory: '전체',
    enCategory: 'All',
    jpCategory: '全体',
  },
];

const initial = {
  mediaList: {
    ...initialList,
    query: {
      lang: '',
      categoryIdx: 0,
      page: 1,
      size: 6,
      hasMore: 0,
    },
  },
  mediaMap: {
    total: 0,
    contents: {},
  },
  detail: {
    src: '',
    type: '',
    title: '',
  },
  category: [...initialCategory],
  banner: {},
};

export function createMediaStore(rootStore) {
  const store = makeAutoObservable({
    mediaList: initial.mediaList,
    mediaMap: initial.mediaMap,
    mediaDetail: [],
    category: initial.category,
    banner: initial.banner,
    detail: initial.detail,

    setListQuery(listName, query) {
      if (!_has(this, listName)) return;
      runInAction(() => {
        this[listName].query = {
          ...this[listName].query,
          ...query,
        };
      });
    },

    setDetailData(data) {
      runInAction(() => {
        if (data.type == 'image') {
          this.detail.src = data.imgUrl;
        } else {
          this.detail.src = data.youtubeUrl;
        }
        this.detail.type = data.type;
        this.detail.title = data.title;
      });
    },

    async getDetailData(idx) {
      try {
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(`/media/board/` + idx);
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            if (data.type == 'image') {
              this.detail.src = data.imgUrl;
            } else {
              this.detail.src = data.youtubeUrl;
            }
            this.detail.type = data.type;
            this.detail.title = data.title;
          });
          return true;
        }
      } catch (err) {
        console.error('getFooter.v::', err);
      }
      return false;
    },

    async getMainBanner() {
      try {
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(`/media/banner`);
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;
          runInAction(() => {
            this.banner = data;
          });
          return true;
        }
      } catch (err) {
        console.error('getFooter.v::', err);
      }
      return false;
    },

    async getMediaList() {
      try {
        const reqParams = getSearchForm(this.mediaList.query, true);
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(reqParams.lang) && reqParams.lang === 'kr')
          reqParams.lang = 'ko';

        let res = await axios.post(
          `/media/board`,
          paramsToFormdata({ ...reqParams })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;
          const categoryMap = {};

          data.contents.forEach(function (e) {
            if (categoryMap[e['categoryIdx']]) {
              categoryMap[e['categoryIdx']].push(e);
            } else {
              categoryMap[e['categoryIdx']] = [];
              categoryMap[e['categoryIdx']].push(e);
            }
          });

          runInAction(() => {
            this.mediaList.contents = data.contents;
            this.mediaMap.total = data.contents.length;
            this.mediaMap.contents = categoryMap;

            this.mediaList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.mediaList.totalElements = data.totalElements;
            this.mediaList.size = data.size;
            this.mediaList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getMediaList.v::', err);
      }
      return false;
    },

    async getMediaCategory() {
      try {
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(`/media/category`);
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.category = data.contents;
          });

          return true;
        }
      } catch (err) {
        console.error('getMediaCategory.v::', err);
      }
      return false;
    },
  });
  return store;
}
