import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

import { createTestStore } from '@stores/TestStore';
import { createMainStore } from '@stores/MainStore';
import { createGameStore } from '@stores/GameStore';
import { createCouponStore } from '@stores/CouponStore';
import { createCommunityStore } from '@stores/CommunityStore';
import { createMediaStore } from '@stores/MediaStore';
import { createRankingStore } from '@stores/RankingStore';
import { createDevlogStore } from '@stores/DevlogStore';
import { createSettingStore } from '@stores/SettingStore';
import { createEventsStore } from '@stores/EventsStore';

import { configure } from 'mobx';
configure({ useProxies: 'never' });

function createRootStore() {
  const store = makeAutoObservable({
    // obesrvables
    // globalStore: null,
    // actions
    mainStore: null,
    setNodeStores(nodeStores) {
      runInAction(() => {
        for (const storeName in nodeStores) {
          this[storeName] = nodeStores[storeName];
        }
      });
    },
  });
  return store;
}

const rootStore = createRootStore();

rootStore.setNodeStores({
  testStore: createTestStore(rootStore),
  mainStore: createMainStore(rootStore),
  gameStore: createGameStore(rootStore),
  couponStore: createCouponStore(rootStore),
  communityStore: createCommunityStore(rootStore),
  mediaStore: createMediaStore(rootStore),
  rankingStore: createRankingStore(rootStore),
  devlogStore: createDevlogStore(rootStore),
  settingStore: createSettingStore(rootStore),
  eventsStore: createEventsStore(rootStore),
});

const StoreContext = React.createContext();

export function StoreProvider(props) {
  return (
    <StoreContext.Provider value={rootStore}>
      {props.children}
    </StoreContext.Provider>
  );
}

export function useMobxStores() {
  return React.useContext(StoreContext);
}
