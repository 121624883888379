import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="relative h-screen w-full">
      <img
        src="/img/v2/bg_404.png"
        alt=""
        className="h-full w-full object-cover"
      />

      <div className="pt-[30%] md:pt-5 text-white text-center w-full text-lg md:text-xl absolute top-[40%]  left-1/2 font-notoSans text-center transform -translate-x-1/2 -translate-y-1/2">
        <div className="px-2 text-2xl md:text-4xl font-bold">
          {t('페이지를 찾을 수 없습니다.')}
        </div>
        <p className="drop-shadow pt-5 px-10 font-light ">
          {t('페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.')} <br />
          {t('입력하신 주소가 정확한지 다시 한 번 확인해주세요.')}
        </p>

        <img src="/img/v2/404_char.png" className="m-auto inline-block" />
        <a
          href={`/${i18n.language}/`}
          className="mx-[20%] sm:m-auto max-w-[340px]  text-[#a68df7] flex justify-center items-center  rounded-full text-[#fff] font-bold btn-coupon-style2 text-center py-3 xs:text-2xl"
        >
          {t('홈 화면으로')}
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
