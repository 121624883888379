import { makeAutoObservable, runInAction } from 'mobx';
import { GAMECODE } from '@helpers/EnvHelper';
import { createExternalInstance } from '@helpers/AxiosHelper';
import { t } from 'i18next';

export function createCouponStore(rootStore) {
  const store = makeAutoObservable({
    modalStatus: false,
    couponParam: {},
    message: '',

    setMessage(message) {
      runInAction(() => {
        this.message = message;
      });
    },
    setPostCouponRedeem(param) {
      runInAction(() => {
        this.couponParam = param;
      });
    },

    async postCouponRedeem() {
      try {
        const reqParams = this.couponParam;
        reqParams.gameCode = GAMECODE;
        const axios = createExternalInstance(rootStore);
        const url = '/api/v2/coupon/redeem';

        let msg = '';
        let res = await axios.post(url, reqParams);
        const resData = res.data;

        if (res.status === 200) {
          switch (resData.code) {
            case 0:
              msg =
                t('쿠폰 사용이 완료되었습니다.') +
                '\n' +
                t('지급된 아이템은 게임 내에서 확인해주세요.');
              break;
            case 5001:
              msg = t('없는 쿠폰 코드입니다. 쿠폰 코드를 확인해주세요.');
              break;
            case 5004:
              msg = t('이미 등록된 쿠폰입니다.');
              break;
            case 5003:
              msg = t('해당 쿠폰 이벤트는 종료되었습니다.');
              break;
            case 5011:
              msg = t('이미 동일한 쿠폰을 사용하셨습니다.');
              break;
            case 5012:
              msg =
                t('이벤트 당 1회 사용 가능한 쿠폰입니다.') +
                '\n' + // 줄바꿈 추가
                t('이미 동일한 쿠폰을 사용하셨습니다.');
              break;
            case 5002:
              msg = t(
                '쿠폰 코드 체크가 실패하였습니다. [사용하기]를 한번 더 눌러주세요.'
              );
              break;
            case 4001:
              msg = t('잘못된 정보입니다. 다시 확인해주세요.');
              break;
            case 4031:
            case 4032:
            case 4033:
              msg = t('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
              break;
            default:
              msg = t(
                '쿠폰 코드 체크가 실패하였습니다. [사용하기]를 한번 더 눌러주세요.'
              );
          }
        } else {
          msg = t('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        }

        runInAction(() => {
          this.message = msg;
        });
        return true;
      } catch (err) {
        //console.error("postCouponRedeem.v::", err);
        runInAction(() => {
          this.message = t(
            '서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.'
          );
        });
        return true;
      }
      return false;
    },
  });

  return store;
}
