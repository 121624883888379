import axios from 'axios';
import { API_EXTERNAL_URL, API_URL } from '@helpers/EnvHelper';

export function setInterceptors(instance, rootStore, noAuth) {
  instance.interceptors.response.use(
    function (response) {
      // console.log("response", response);
      return response;
    },
    async function (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          throw error;
        } else if (status === 401) {
          // 401 CASES
          // const result = await authStore.refreshAccessToken();
          // if (result) {
          //   return instance(error.response.config);
          // }
        } else if (status === 500) {
          // 500 CASES
          // alert("문제가 발생하였습니다. 잠시 후 다시 시도해주세요.");
          throw error;
        }
      }

      console.error('response.error::', error.message);
      if (error.response) {
        console.error('response.data::', error.response.data);
      }
      return Promise.reject(error);
    }
  );

  return instance;
}

export function createExternalInstance(rootStore, noAuth) {
  let config = {
    baseURL: API_EXTERNAL_URL,
    timeout: 20000,
  };

  const instanceExternal = axios.create(config);
  return setInterceptors(instanceExternal, rootStore, noAuth);
}

export function createStaticInstance(rootStore, noAuth) {
  let config = {
    baseURL: '',
    timeout: 2000,
  };

  const instance = axios.create(config);
  return setInterceptors(instance, rootStore, noAuth);
}

export default function createInstance(rootStore, noAuth) {
  let config = {
    baseURL: API_URL,
    timeout: 20000,
  };

  const instance = axios.create(config);
  return setInterceptors(instance, rootStore, noAuth);
}
