import { makeAutoObservable, runInAction } from 'mobx';

import createAxiosInstance from '@helpers/AxiosHelper';
import { getSearchForm } from '@helpers/StringHelper';
import { isEmpty as _isEmpty, has as _has } from 'lodash';
import { paramsToFormdata } from '@helpers/StringHelper';

const initialList = {
  contents: [],
  totalElements: 0,
  totalPages: 1,
  page: 1,
  size: 10,
};

const initial = {
  withBTSList: {
    ...initialList,
    query: {
      category: 'RM',
      lang: '',
    },
  },
  withArmyList: {
    ...initialList,
    query: {
      lang: '',
    },
  },
  withArmy: null,
  pdNoteList: {
    ...initialList,
    query: {
      lang: '',
    },
  },
  pdNote: {
    content: '',
    idx: 0,
    lang: 'ko',
    masterIdx: 0,
    startDt: '',
    subtitle: '',
    title: '',
  },
};

export function createDevlogStore(rootStore) {
  const store = makeAutoObservable({
    withBTSList: initial.withBTSList,
    pdNoteList: initial.pdNoteList,
    withArmyList: initial.withArmyList,
    withBTS: null,
    pdNote: null,

    setListQuery(listName, query) {
      if (!_has(this, listName)) return;
      runInAction(() => {
        this[listName].query = {
          ...this[listName].query,
          ...query,
        };
      });
    },

    async getWithBTSList() {
      try {
        const reqParams = getSearchForm(this.withBTSList.query, true);
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(reqParams.lang) && reqParams.lang === 'kr')
          reqParams.lang = 'ko';

        let res = await axios.post(
          `/intheseom/withBTS`,
          paramsToFormdata({ ...reqParams })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;
          runInAction(() => {
            this.withBTSList.contents = data.contents;
            this.withBTSList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.withBTSList.totalElements = data.totalElements;
            this.withBTSList.size = data.size;
            this.withBTSList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getWithBTSList.v::', err);
      }
      return false;
    },

    async getWithBTS(lang, idx) {
      try {
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(
          `/intheseom/withBTS/${idx}`,
          paramsToFormdata({ lang })
        );
        const resData = res.data;
        //console.log(res.data);
        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.withBTS = data;
          });

          return true;
        }
      } catch (err) {
        console.error('getWithBTS.v::', err);
        window.history.back();
      }
      return false;
    },

    async getPdNoteList() {
      try {
        const reqParams = getSearchForm(this.pdNoteList.query, true);
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(reqParams.lang) && reqParams.lang === 'kr')
          reqParams.lang = 'ko';
        let res = await axios.post(
          `/intheseom/pd-note`,
          paramsToFormdata({ ...reqParams })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.pdNoteList.contents = data.contents;
            this.pdNoteList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.pdNoteList.totalElements = data.totalElements;
            this.pdNoteList.size = data.size;
            this.pdNoteList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getPdNoteList.v::', err);
      }
      return false;
    },

    async getPdNote(lang, idx) {
      try {
        if (_isEmpty(idx) || idx === 0) {
          runInAction(() => {
            this.pdNote = null;
          });
          return true;
        }

        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(
          `/intheseom/pd-note/${idx}`,
          paramsToFormdata({ lang })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.pdNote = data;
          });

          return true;
        }
      } catch (err) {
        console.error('getPdNote.v::', err);
      }
      return false;
    },

    async getWithArmyList() {
      try {
        const reqParams = getSearchForm(this.withArmyList.query, true);
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(reqParams.lang) && reqParams.lang === 'kr')
          reqParams.lang = 'ko';
        let res = await axios.post(
          `/community/notice`,
          paramsToFormdata({ ...reqParams })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.withArmyList.contents = data.contents;
            this.withArmyList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.withArmyList.totalElements = data.totalElements;
            this.withArmyList.size = data.size;
            this.withArmyList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('withArmyList.v::', err);
      }
      return false;
    },

    async getWithArmyDetail(idx, lang) {
      try {
        // const reqParams = getSearchForm(this.noticeList.query, true);
        if (_isEmpty(idx) || idx === 0) {
          runInAction(() => {
            this.withArmy = null;
          });
          return true;
        }
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(
          `/community/notice/${idx}`,
          paramsToFormdata({ lang })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.withArmy = data;
          });

          return true;
        }
      } catch (err) {
        console.error('getNotice.v::', err);
      }
      return false;
    },
  });

  return store;
}
