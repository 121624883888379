import { makeAutoObservable, runInAction } from 'mobx';

import createAxiosInstance from '@helpers/AxiosHelper';
import { paramsToFormdata } from '@helpers/StringHelper';
import { isEmpty as _isEmpty } from 'lodash';
import moment from 'moment';
import i18next from 'i18next';

export const bgmCodes = {
  game: 'GAME_INTRODUCE',
  devlog: 'DEVELOPMENT_LOG',
  community: 'COMMUNITY_G',
};

export const consentCookiesKey = {
  cookies: 'consentCookie',
  times: 'consentCookieTime',
  expire: 'consentExpireTime',
};

export const expireConfig = {
  amount: 12,
  unit: 'month',
};

const initial = {
  policyList: [],
};

export function createSettingStore(rootStore) {
  const store = makeAutoObservable({
    isLinkState: false,
    dialogState: false,
    mediaDialogState: false,
    commonDialogState: false,
    commonDialogSrcState: '',
    gdprDialogState: false,
    gdprDetailDialogState: false,
    bgmList: {},
    bgmPlayStatus: {},
    footer: [],
    privacyList: initial.privacyList,
    termsList: initial.termsList,
    customPageContents: null,

    setIsLinkState(flag) {
      runInAction(() => {
        this.isLinkState = flag;
      });
    },

    setBgmInit() {
      runInAction(() => {
        this.bgmList = {};
      });
    },

    setDialogState(flag) {
      runInAction(() => {
        this.dialogState = flag;
      });
    },

    setCommonDialogState(flag) {
      runInAction(() => {
        this.commonDialogState = flag;
      });
    },
    setCommonDialogSrcState(src) {
      runInAction(() => {
        this.commonDialogSrcState = src;
      });
    },

    setMediaDialogState(flag) {
      runInAction(() => {
        this.mediaDialogState = flag;
      });
    },

    setGdprDialogState(flag) {
      runInAction(() => {
        this.gdprDialogState = flag;
      });
    },

    setGdprDetailDialogState(flag) {
      runInAction(() => {
        this.gdprDetailDialogState = flag;
      });
    },

    setBgmPlayStatus(menuName, status) {
      runInAction(() => {
        this.bgmPlayStatus = {
          ...this.bgmPlayStatus,
          [menuName]: status,
        };
      });
    },

    async getBgm(code) {
      // this.setBgmInit();
      if (_isEmpty(code)) {
        this.setBgmInit();
        return;
      }
      try {
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(`/setting/bgm`, paramsToFormdata({ code }));
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;
          runInAction(() => {
            this.bgmList = {
              ...this.bgmList,
              [code]: data,
            };
            this.bgmPlayStatus = {
              ...this.bgmPlayStatus,
              [code]:
                this.bgmPlayStatus[code] > -1
                  ? this.bgmPlayStatus[code]
                  : !_isEmpty(data) && data.length > 0
                  ? data[0].autoPlay
                  : -1,
            };
          });
          return true;
        }
      } catch (err) {
        console.error('getBgm.v::', err);

        runInAction(() => {
          this.bgm = [];
        });
      }
      return false;
    },

    async getFooter() {
      try {
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(`/setting/footer`);
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.footer = data;
          });
          return true;
        }
      } catch (err) {
        console.error('getFooter.v::', err);
      }
      return false;
    },

    async getPolicy(lang, type) {
      try {
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(
          `/setting/${type}`,
          paramsToFormdata({ lang })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          !_isEmpty(data) &&
            data.length > 0 &&
            data.forEach((row, index) => {
              row.id = row.idx;
              row.name = `${row.ver} / ${moment(data[index]?.regDt).format(
                'YYYY-MM-DD'
              )} ~ ${
                index === data.length - 1
                  ? '2024.07.16'
                  : moment(data[index + 1]?.regDt)
                      .subtract(1, 'days')
                      .format('YYYY-MM-DD')
              }`;
            });

          runInAction(() => {
            this.policyList = data;
          });

          return true;
        }
      } catch (err) {
        console.error('getPolicy.v::', err);
      }
      return false;
    },

    async getPages(lang, code) {
      try {
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        if (!code) return false;
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(
          `setting/pages/${code}`,
          paramsToFormdata({ lang })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.customPageContents = data;
          });

          return true;
        }
      } catch (err) {
        console.error('getPolicy.v::', err);
      }
      return false;
    },
  });

  return store;
}
