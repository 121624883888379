import { isNumber as _isNumber, toNumber as _toNumber } from 'lodash';

export const ARRAY_CURRENT_LANGUAGE = ['kr', 'en', 'jp'];
export const EVENT1_NOTICE_LINK = `/community/event/540`;
export const EVENT1_NOTICE_MOBILE_LINK =
  'https://cqp6.adj.st/mailbox/announcement?adjust_t=1cfnfvgx';

export const EVENT2_NOTICE_LINK = '/community/event/541';
export const EVENT2_NOTICE_MOBILE_LINK =
  'https://cqp6.adj.st/mailbox/announcement?adjust_t=1chsxgfy';

export const EVENT3_NOTICE_LINK = '/community/event/543';
export const EVENT3_NOTICE_MOBILE_LINK =
  'https://cqp6.adj.st/mailbox/announcement?adjust_t=1c6030vo';

export const EVENT_JP_NOTICE01_LINK = '/community/event/542';
export const EVENT_JP_NOTICE01_MOBILE_LINK =
  'https://cqp6.adj.st/mailbox/announcement?adjust_t=1c6030vo';

export const EVENT_JP_NOTICE02_LINK = '/community/event/544';
export const EVENT_JP_NOTICE02_MOBILE_LINK =
  'https://cqp6.adj.st/mailbox/announcement?adjust_t=1cbctq6s';

export const YOUTUBE_VIDEO_ID_01 = {
  kr: 'hPe_dHx9MrU',
  en: '-5VXwLI_Vf4',
  jp: 'C3Tuxc1Ie3g',
};

export const YOUTUBE_VIDEO_ID_02 = {
  kr: '3wxG1WLDONI',
  en: '3wxG1WLDONI',
  jp: '3wxG1WLDONI',
};

export const event0601GetStep = count => {
  if (!_isNumber(count)) {
    return 0;
  }
  let current_step = 0;
  if (count >= 4500000) {
    current_step = 5;
  } else if (count >= 4000000) {
    current_step = 4;
  } else if (count >= 3000000) {
    current_step = 3;
  } else if (count >= 2000000) {
    current_step = 2;
  } else if (count >= 1000000) {
    current_step = 1;
  }

  return _toNumber(current_step);
};
