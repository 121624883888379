import { makeAutoObservable, runInAction } from 'mobx';
import {
  has as _has,
  // forEach as _forEach,
  // filter as _filter
} from 'lodash';

import createAxiosInstance from '@helpers/AxiosHelper';
import { getSearchForm } from '@helpers/StringHelper';

const initialList = {
  contents: [],
  totalElements: 0,
  totalPages: 1,
  page: 1,
  size: 10,
};

const initial = {
  testList: {
    ...initialList,
    allRowSelected: false,
    query: {
      page: '',
    },
  },
};

export function createTestStore(rootStore) {
  const store = makeAutoObservable({
    testList: initial.testList,

    setListQuery(listName, query, init) {
      if (!_has(this, listName)) throw Error('존재하지 않는 listName 입니다');

      runInAction(() => {
        this[listName].query = init
          ? {
              ...initial[listName].query,
              ...query,
            }
          : {
              ...this[listName].query,
              ...query,
            };
      });
    },

    async getTestList(listName) {
      const params = getSearchForm({ ...this.testList.query }, true);
      try {
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(`/account/list`, { ...params });
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          // _forEach(data.content, (v) => {
          // v.selected = false;
          // });

          runInAction(() => {
            this.testList.contents = data.contents;
            this.testList.pagination = {
              totalPages: data.totalPages === 0 ? 1 : data.totalPages,
              totalElements: data.totalElements,
              size: data.size,
              page: data.page,
            };
          });

          return true;
        }
      } catch (err) {
        console.error('TestStore.v::', err);
      }
      return false;
    },
  });

  return store;
}
