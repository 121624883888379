import {
  isEmpty as _isEmpty,
  isNumber as _isNumber,
  toString as _toString,
  filter as _filter,
  replace as _replace,
} from 'lodash';

export const numberStringWithComma = number => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return number.toString().replace(regexp, ',');
};

export const getQuerySetUrl = (form, isPaging = true) => {
  let toUrl = isPaging ? '?pageNumber=1' : '?';
  let count = 0;
  for (const key in form) {
    if (!_isEmpty(form[key])) {
      if (!isPaging && count === 0) {
        toUrl += `${key}=${form[key]}`;
      } else if (key !== 'pageNumber') {
        toUrl += `&${key}=${form[key]}`;
      }
      count++;
    }
  }
  return toUrl;
};

export const getSearchForm = (query, filter = false) => {
  let newForm = {};
  for (const key in query) {
    if (filter) {
      if (!_isEmpty(_toString(query[key]))) {
        newForm[key] = query[key];
      }
    } else {
      newForm[key] = query[key];
    }
  }
  return newForm;
};

export const numberOnly = (text, format) => {
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  let _num = _isNumber(text)
    ? text
    : text.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  _num = _num === '' ? 0 : parseInt(_num, 10);
  if (format) {
    _num = _num.toString().replace(regex, ',');
  }
  return _num;
};

export const getPathName = (path, subtract = 0) => {
  const split = _filter(path.split('/'), s => s !== '');
  const lastIndex = split.length - subtract;
  let pathname = '';
  for (let i = 0; i < lastIndex; i++) {
    pathname += `/${split[i]}`;
  }
  return pathname;
};

export const toHtml = str => {
  let newStr = str;
  newStr = _replace(newStr, /&amp;/, '&');
  newStr = _replace(newStr, /&gt;/g, '>');
  newStr = _replace(newStr, /&lt;/, '<');
  newStr = _replace(newStr, /&lt;/, '<');
  newStr = _replace(newStr, /\n/g, '<br />');
  return newStr;
};

export const paramsToFormdata = params => {
  let form = new FormData();
  for (let attr in params) {
    form.append(attr, params[attr]);
  }
  return form;
};
