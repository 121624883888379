import { makeAutoObservable, runInAction } from 'mobx';
import {
  has as _has,
  forEach as _forEach,
  // filter as _filter
  isEmpty as _isEmpty,
} from 'lodash';

import createAxiosInstance from '@helpers/AxiosHelper';
import { paramsToFormdata } from '@helpers/StringHelper';

const initialList = {
  contents: [],
  totalElements: 0,
  totalPages: 1,
  page: 1,
  size: 10,
};

const initial = {
  keyvisualList: {
    ...initialList,
    query: {
      lang: '',
    },
  },

  noticeList: {
    ...initialList,
    query: {
      lang: '',
    },
  },
  pdNoteList: {
    ...initialList,
    query: {
      lang: '',
    },
  },
  popupList: {
    ...initialList,
    query: {
      lang: '',
    },
  },
};

export function createMainStore(rootStore) {
  const store = makeAutoObservable({
    isActive: false,
    keyvisualList: initial.keyvisualList,
    noticeList: initial.noticeList,
    popupList: initial.popupList,
    withBTSList: initial.withBTSList,
    pdNoteList: initial.pdNoteList,

    setIsActive(isActive) {
      runInAction(() => {
        this.isActive = isActive;
      });
    },

    setListQuery(listName, query, init) {
      if (!_has(this, listName)) throw Error('존재하지 않는 listName 입니다');

      runInAction(() => {
        this[listName].query = init
          ? {
              ...initial[listName].query,
              ...query,
            }
          : {
              ...this[listName].query,
              ...query,
            };
      });
    },

    async getKeyvisual(lang) {
      try {
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(
          `/main/keyvisual`,
          paramsToFormdata({ lang })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.keyvisualList.contents = data.contents;
            this.keyvisualList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.keyvisualList.totalElements = data.totalElements;
            this.keyvisualList.size = data.size;
            this.keyvisualList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getKeyvisual.v::', err);
      }
      return false;
    },

    async getNotice(lang) {
      try {
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(`/main/notice`, paramsToFormdata({ lang }));

        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          // _forEach(data.content, (v) => {
          // v.selected = false;
          // });

          runInAction(() => {
            this.noticeList.contents = data.contents;
            this.noticeList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.noticeList.totalElements = data.totalElements;
            this.noticeList.size = data.size;
            this.noticeList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getNotice.v::', err);
      }
      return false;
    },

    async getWithBTS(lang) {
      try {
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(`/main/withBTS`, paramsToFormdata({ lang }));

        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          // _forEach(data.content, (v) => {
          // v.selected = false;
          // });

          runInAction(() => {
            this.withBTSList.contents = data.contents;
            this.withBTSList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.withBTSList.totalElements = data.totalElements;
            this.withBTSList.size = data.size;
            this.withBTSList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getWithBTS.v::', err);
      }
      return false;
    },

    async getPopup(lang) {
      try {
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(`/main/popup`, paramsToFormdata({ lang }));

        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          _forEach(data.contents, v => {
            // v.selected = false;
            const NO_SHOW_TODAY = localStorage.getItem(
              'NO_SHOW_TODAY_' + v.boardIdx
            );
            v.isShow =
              NO_SHOW_TODAY && NO_SHOW_TODAY > new Date() ? false : true;
          });

          runInAction(() => {
            this.popupList.contents = data.contents;
            this.popupList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.popupList.totalElements = data.totalElements;
            this.popupList.size = data.size;
            this.popupList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getNotice.v::', err);
      }
      return false;
    },

    async getPdNote(lang) {
      try {
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(
          `/main/pd-note`,
          paramsToFormdata({ lang, size: 100 })
        );

        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.pdNoteList.contents = data.contents;
            this.pdNoteList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.pdNoteList.totalElements = data.totalElements;
            this.pdNoteList.size = data.size;
            this.pdNoteList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getNotice.v::', err);
      }
      return false;
    },
  });

  return store;
}
