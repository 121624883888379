import { makeAutoObservable, runInAction } from 'mobx';

import createAxiosInstance from '@helpers/AxiosHelper';
import { getSearchForm } from '@helpers/StringHelper';
import { isEmpty as _isEmpty, has as _has } from 'lodash';
import { paramsToFormdata } from '@helpers/StringHelper';

const initialList = {
  contents: [],
  totalElements: 0,
  totalPages: 1,
  page: 1,
  size: 10,
};

const initial = {
  noticeList: {
    ...initialList,
    query: {
      lang: '',
      page: 1,
      size: 6,
    },
  },
  eventList: {
    ...initialList,
    query: {
      lang: '',
      page: 1,
      size: 6,
    },
  },
  searchList: {
    ...initialList,
    query: {
      lang: '',
      page: 1,
      size: 6,
      keyword: '',
      board: 'notice',
    },
  },
};

export function createCommunityStore(rootStore) {
  const store = makeAutoObservable({
    noticeList: initial.noticeList,
    eventList: initial.eventList,
    searchList: initial.searchList,
    faqList: initial.faqList,
    notice: null,
    category: initial.category,

    setListQuery(listName, query) {
      if (!_has(this, listName)) return;
      runInAction(() => {
        this[listName].query = {
          ...this[listName].query,
          ...query,
        };
      });
    },

    async getNoticeList() {
      try {
        const reqParams = getSearchForm(this.noticeList.query, true);
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(reqParams.lang) && reqParams.lang === 'kr')
          reqParams.lang = 'ko';

        let res = await axios.post(
          `/community/notice`,
          paramsToFormdata({ ...reqParams })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;
          //console.error('getNoticeList.v::', data);
          runInAction(() => {
            this.noticeList.contents = data.contents;
            this.noticeList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.noticeList.totalElements = data.totalElements;
            this.noticeList.size = data.size;
            this.noticeList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getNoticeList.v::', err);
      }
      return false;
    },
    async getEventList() {
      try {
        const reqParams = getSearchForm(this.eventList.query, true);
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(reqParams.lang) && reqParams.lang === 'kr')
          reqParams.lang = 'ko';

        let res = await axios.post(
          `/community/event`,
          paramsToFormdata({ ...reqParams })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;
          //console.error('getEventList.v::', data);
          runInAction(() => {
            this.eventList.contents = data.contents;
            this.eventList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.eventList.totalElements = data.totalElements;
            this.eventList.size = data.size;
            this.eventList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getNoticeList.v::', err);
      }
      return false;
    },
    async getNotice(idx, lang) {
      try {
        // const reqParams = getSearchForm(this.noticeList.query, true);
        if (_isEmpty(idx) || idx === 0) {
          runInAction(() => {
            this.notice = null;
          });
          return true;
        }
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(
          `/community/notice/${idx}`,
          paramsToFormdata({ lang })
        );
        const resData = res.data;
        //console.error('getNotice.v::', resData);
        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.notice = data;
          });

          return true;
        }
      } catch (err) {
        console.error('getNotice.v::', err);
      }
      return false;
    },
    async getNoticeByKeyword(board, keyword) {
      try {
        const reqParams = getSearchForm(this.searchList.query, true);
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(reqParams.lang) && reqParams.lang === 'kr')
          reqParams.lang = 'ko';
        if (['event', 'notice'].includes(board)) {
          reqParams.board = board;
        }
        keyword.replace(
          /[^a-zA-Z\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\u3131-\uD7A3\s0-9]/g,
          ''
        );
        reqParams.keyword = keyword.replace(/['/]/g, '');

        let res = await axios.post(
          `/community/search`,
          paramsToFormdata({ ...reqParams })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;
          console.error('searchList.v::', data);
          runInAction(() => {
            this.searchList.contents = data.contents;
            this.searchList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.searchList.totalElements = data.totalElements;
            this.searchList.size = data.size;
            this.searchList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getNoticeList.v::', err);
      }
      return false;
    },
    async getFaqCategory() {
      try {
        const axios = createAxiosInstance(rootStore);
        let res = await axios.post(`/community/category`);
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.category = data.contents;
          });

          return true;
        }
      } catch (err) {
        console.error('getFaqList.v::', err);
      }
      return false;
    },

    async getFaqList(lang, category) {
      try {
        const axios = createAxiosInstance(rootStore);
        if (!_isEmpty(lang) && lang === 'kr') lang = 'ko';
        let res = await axios.post(
          `/community/faq`,
          paramsToFormdata({ lang, category })
        );
        const resData = res.data;

        if (resData.code === 200) {
          const data = resData.data;

          runInAction(() => {
            this.faqList.contents = data.contents;
            this.faqList.totalPages =
              data.totalPages === 0 ? 1 : data.totalPages;
            this.faqList.totalElements = data.totalElements;
            this.faqList.size = data.size;
            this.faqList.page = data.page;
          });

          return true;
        }
      } catch (err) {
        console.error('getFaqList.v::', err);
      }
      return false;
    },
  });
  return store;
}
