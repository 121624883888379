import { lazy } from 'react';
import { LANGUAGE } from '@helpers/EnvHelper';
import {
  mapKeys as _mapKeys,
  forEach as _forEach,
  concat as _concat,
} from 'lodash';

// import PdNotePage from "@app/pages/devlog/PdNotePage";

// Templates
const DefaultLayout = lazy(() => import('@templates/DefaultLayout'));
const DefaultEmptyLayout = lazy(() => import('@templates/DefaultEmptyLayout'));
const DefaultBlackLayout = lazy(() => import('@templates/DefaultBlackLayout'));
const DefaultEventLayout = lazy(() => import('@templates/DefaultEventLayout'));

const MainPage = lazy(() => import('@pages/MainPage'));
const NotFoundPage = lazy(() => import('@pages/NotFoundPage'));
const GamePage = lazy(() => import('@app/pages/game/GamePage'));

// const DevlogPage = lazy(() => import("@pages/devlog/DevlogPage"));
const WithBTSPage = lazy(() => import('@pages/devlog/WithBTSPage'));
const WithBTSDetailPage = lazy(() => import('@pages/devlog/WithBTSDetailPage'));

const EventPage = lazy(() => import('@app/pages/community/EventPage'));
const NoticePage = lazy(() => import('@app/pages/community/NoticePage'));
const BoardDetailPage = lazy(() => import('@pages/community/BoardDetailPage'));

const PrivacyPage = lazy(() => import('@pages/policy/PrivacyPage'));
const OperationPage = lazy(() => import('@pages/policy/OperationPage'));
const ServicePage = lazy(() => import('@pages/policy/ServicePage'));
const AddPage = lazy(() => import('@pages/policy/AddPage'));
const CookiesPage = lazy(() => import('@pages/policy/CookiesPage'));
const CouponPage = lazy(() => import('@pages/coupon/CouponPage'));
const RankingPage = lazy(() => import('@pages/ranking/RankingPage'));

const Event20240521 = lazy(() => import('@pages/events/Event20240521'));
const Event20240611 = lazy(() => import('@pages/events/Event20240611'));

const defaultRoutes = [
  {
    path: '/',
    name: '메인',
    exact: true,
    component: MainPage,
    layout: DefaultLayout,
  },
  {
    path: '/:lang(kr|en|jp)',
    name: '메인',
    exact: true,
    component: MainPage,
    layout: DefaultLayout,
  },
  {
    path: '/devlog/withBTS',
    name: 'with BTS',
    component: WithBTSPage,
    layout: DefaultLayout,
  },

  {
    path: '/devlog/withBTS/:idx',
    name: 'with BTS',
    component: WithBTSDetailPage,
    layout: DefaultLayout,
  },
  {
    path: '/ranking',
    name: '랭킹',
    component: RankingPage,
    layout: DefaultLayout,
  },

  {
    path: '/community/news',
    name: '공지사항',
    component: NoticePage,
    layout: DefaultLayout,
  },
  {
    path: '/community/event',
    name: '이벤트',
    component: EventPage,
    layout: DefaultLayout,
  },
  {
    path: '/community/news/:newsIdx',
    name: '공지사항',
    component: BoardDetailPage,
    layout: DefaultLayout,
  },
  {
    path: '/community/event/:newsIdx',
    name: '이벤트',
    component: BoardDetailPage,
    layout: DefaultLayout,
  },
  {
    path: '/coupon',
    name: '쿠폰등록',
    component: CouponPage,
    layout: DefaultLayout,
  },
  {
    path: '/policy/page/:code',
    name: 'Page',
    component: AddPage,
    layout: DefaultLayout,
  },
  {
    path: '/policy/privacy-policy',
    name: '개인정보처리방침',
    component: PrivacyPage,
    layout: DefaultBlackLayout,
  },
  {
    path: '/policy/operation-policy',
    name: '운영정책',
    component: OperationPage,
    layout: DefaultBlackLayout,
  },
  {
    path: '/policy/terms',
    name: '이용약관',
    component: ServicePage,
    layout: DefaultBlackLayout,
  },
  {
    path: '/policy/cookies',
    name: '쿠키 정책',
    component: CookiesPage,
    layout: DefaultBlackLayout,
  },
  {
    path: '/ranking/:type',
    name: '랭킹',
    component: RankingPage,
    layout: DefaultLayout,
  },
  {
    path: '/404',
    name: 'not found page',
    component: NotFoundPage,
    layout: DefaultLayout,
  },
  {
    path: '/*',
    name: 'not found page',
    component: NotFoundPage,
    layout: DefaultLayout,
  },
];

const inGameRoutes = [
  {
    path: '/link/coupon',
    name: '(링크) 쿠폰등록',
    component: CouponPage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/community/event/:newsIdx',
    name: '이벤트',
    component: BoardDetailPage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/community/news/:newsIdx',
    name: '(게임) 공지사항/event',
    component: BoardDetailPage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/policy/privacy-policy',
    name: '(게임) 개인정보처리방침',
    component: PrivacyPage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/policy/terms',
    name: '(게임) 이용약관',
    component: ServicePage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/ranking',
    name: '랭킹',
    component: RankingPage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/ranking/:type',
    name: '랭킹',
    component: RankingPage,
    layout: DefaultEmptyLayout,
  },

  {
    path: '/link/policy/page/:code',
    name: '페이지',
    component: AddPage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/policy/cookies',
    name: '쿠키 정책',
    component: CookiesPage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/events/2nd_anniversary_teaser',
    name: '이벤트',
    component: Event20240521,
    layout: DefaultEventLayout,
  },
  {
    path: '/link/community/news',
    name: '공지사항',
    component: NoticePage,
    layout: DefaultEmptyLayout,
  },
  {
    path: '/link/community/event',
    name: '이벤트',
    component: EventPage,
    layout: DefaultEmptyLayout,
  },
];

const eventRoutes = [
  {
    path: '/events/2nd_anniversary_teaser',
    name: '이벤트',
    component: Event20240521,
    layout: DefaultEventLayout,
    desc: 'EVENT0611_SNS_TITLE',
  },
  {
    path: '/events/2nd_anniversary',
    name: '이벤트',
    component: Event20240611,
    layout: DefaultEventLayout,
    desc: 'EVENT0611_SNS_TITLE',
  },
];

export const getRoutes = () => {
  let routes = [];
  _mapKeys(defaultRoutes, data => {
    _forEach(LANGUAGE, lang => {
      routes = _concat(routes, {
        ...data,
        path: `/${lang}${data.path}`,
        name: `${data.name}`,
        isLink: false,
      });
    });
  });

  _mapKeys(inGameRoutes, data => {
    _forEach(LANGUAGE, lang => {
      routes = _concat(routes, {
        ...data,
        path: `/${lang}${data.path}`,
        name: `${data.name}`,
        isLink: true,
      });
    });
  });

  _mapKeys(eventRoutes, data => {
    _forEach(LANGUAGE, lang => {
      routes = _concat(routes, {
        ...data,
        path: `/${lang}${data.path}`,
        name: `${data.name}`,
        isLink: false,
      });
    });
  });

  return [...defaultRoutes, ...inGameRoutes, ...eventRoutes, ...routes];
};

// export const getRoutes = () => {
//   return [...defaultRoutes];
// };
