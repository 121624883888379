import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from '@lang/lang.en';
import langKr from '@lang/lang.kr';
import langKo from '@lang/lang.ko';
import langJp from '@lang/lang.jp';

const resource = {
  ko: {
    translation: langKo,
  },
  kr: {
    translation: langKr,
  },
  en: {
    translation: langEn,
  },
  jp: {
    translation: langJp,
  },
};

i18n.use(initReactI18next).init({
  resources: resource,
  // 초기 설정 언어
  lng: 'kr',
  fallbackLng: {
    en: ['en'],
    jp: ['jp'],
    kr: ['kr'],
    ko: ['ko'],
    default: ['kr'],
  },
  // debug: true,
  defaultNS: 'translation',
  ns: 'translation',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
