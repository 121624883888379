import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMobxStores } from '@stores/store';

export default function PageInit() {
  const { pathname } = useLocation();
  const { settingStore } = useMobxStores();

  useEffect(() => {
    window.scrollTo(0, 0);
    // settingStore.setBgmInit();
  }, [pathname, settingStore]);

  return null;
}
